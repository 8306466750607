<template>
  <div id="award-type-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <!-- <v-card-title></v-card-title> -->
      <v-card-text>
        <div class="d-flex justify-content-center mt-2 mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="confirmAlert"
        >
          <v-container class="py-0 mt-2" v-if="record != ''">
            <h5 class="blue--text text-center">Category Details</h5>
            <v-row wrap class="mb-3">
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Status</h6>
                <v-chip :color="record.RecognitionStatusColor" draggable dark>
                  {{ record.RecognitionStatusTxt }}
                </v-chip>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Date</h6>
                <h6 class="text-dark">
                  {{ record.RecognitionDate }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Category Title</h6>
                <h6 class="text-dark">
                  {{ record.CategoryTitle }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Category Description</h6>
                <h6 class="text-dark">
                  {{ record.CategoryDescription }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Category Type</h6>
                <h6 class="text-dark">
                  {{ record.CategoryTypeTxt }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Lom Name</h6>
                <h6 class="text-dark">
                  {{ record.LomName }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Portfolio Type</h6>
                <h6 class="text-dark">
                  {{ record.PortfolioName }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Created At</h6>
                <h6 class="text-dark">
                  {{ record.CreatedAt }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Updated At</h6>
                <h6 class="text-dark">
                  {{ record.UpdatedAt }}
                </h6>
              </v-col>
              <v-col cols="12" sm="12" lg="12" md="12">
                <h6>Description</h6>
                <h6 class="text-dark">
                  {{ Description }}
                </h6>
              </v-col>
            </v-row>
            <hr />
            <h5 class="blue--text text-center">President Details</h5>
            <v-row wrap class="mb-0" v-if="LomData != ''">
              <!-- <v-col cols="12" sm="6" lg="4" md="6">
                <h6 class="text-dark">Lom Name</h6>
                <h6>
                  {{ LomData.LomName }}
                </h6>
              </v-col> -->
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>President Image</h6>
                <img
                  width="50"
                  height="auto"
                  :src="LomData.President.MemberImagePath"
                />
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>President Name</h6>
                <h6 class="text-dark">
                  {{ LomData.President.LomPresidentFullName }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>President Membership Id</h6>
                <h6 class="text-dark">
                  {{ LomData.President.LomPresidentMembershipId }}
                </h6>
              </v-col>
            </v-row>
            <hr />
            <h5 class="blue--text text-center">Member Details</h5>
            <v-row v-if="MemberId > 0 && member != ''" class="text-dark">
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img width="100" height="auto" :src="member.photo" />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Name</label>
                <h5>{{ member.member_name }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Membership Id</label>
                <h5>{{ member.membership_id }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Date of Birth</label>
                <h5>{{ member.member_dob }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Mobile No</label>
                <h5>{{ member.mobile_no }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Email Id</label>
                <h5>{{ member.email_id }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="9">
                <label>Address</label>
                <h5>{{ member.address_txt }}</h5>
              </v-col>
            </v-row>
            <hr />
            <h6 class="blue--text text-center">
              Attachment Details (max - {{ CategoryMaxCount }})
            </h6>

            <template v-if="AllProject.length > 0">
              <v-row class="ml-0">
                <v-col
                  v-for="(data, index) in AllProject"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="3"
                  xl="3"
                  :key="index"
                >
                  <v-card>
                    <v-card-text>
                      <h6 align="center">
                        <viewer rebuild :images="data.EventPhoto">
                          <img
                            style="padding: 5px"
                            width="70"
                            height="70"
                            :src="src"
                            :key="src"
                            v-for="src in data.EventPhoto"
                          />
                        </viewer>
                      </h6>
                      <h6>
                        Event Date -
                        <span class="text-dark">{{ data.EventDate }}</span>
                      </h6>
                      <h6>
                        Area-
                        <span class="text-dark">{{ data.PortfolioName }}</span>
                      </h6>
                      <h6>
                        Event Name -
                        <span class="text-dark">{{ data.EventName }}</span>
                      </h6>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-container>
          <v-row wrap class="mt-4">
            <v-col align="center" cols="12" md="12">
              <v-btn
                @click="closePrompt"
                elevation="30"
                medium
                color="red"
                class="font-size-h6 px-3 mr-3 white--text"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordPrompt: {
      required: true,
      type: Boolean,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      record: {},
      LomData: {},
      member: {},

      AllProject: [],
      row: {},

      Description: "",

      addAttachmentPrompt: false,

      LomCode: 0,
      CategoryMaxCount: 0,

      // MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      YearCode: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        this.YearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    MemberIdOptions: function () {
      console.log("watch MemberIdOptions called.");

      this.MemberIdOptionsLoading = false;
      var tr = this.record;
      this.MemberId = tr.MemberId;
    },
    MemberId: function () {
      console.log("watch MemberId");
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log({ member });
      }
      this.getMemberDetails();
    },
    record: function () {
      console.log("Watch record called");
      var tr = this.record;
      console.log("tr =" + JSON.stringify(tr));

      this.Description = tr.RecognitionDescription;
      this.CategoryMaxCount = tr.CategoryMaxCount;
      this.LomCode = tr.LomId;

      this.getLomDetails();
      this.getMemberIdOptions();
      this.AllProject = [];

      tr.EventDetails.forEach((row) => {
        this.AllProject.push({
          EventId: row["EventId"],
          EventName: row["EventName"],
          EventDate: row["EventDate"],
          PortfolioId: row["PortfolioId"],
          PortfolioName: row["PortfolioName"],
          EventDescription: row["EventDescription"],
          EventPhoto: row["EventImagesArray"],
        });
      });

      console.log("AllProject =" + JSON.stringify(this.AllProject));
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "np_recognition",
        Action: "preview",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt", this.ResultFlag);
    },
    pageData() {
      console.log("pageData called");

      console.log("recordData =" + JSON.stringify(this.recordData));
      var id = this.recordData.RecognitionId;

      console.log({ id });
      if (id > 0) {
        this.getTableRecords(id);
      }
    },
    getLomDetails() {
      console.log("getLomDetails called");
      this.SubmitFlag = true;
      this.ResultFlag = false;

      var upload = {
        UserInterface: 1,
        Lom: this.LomCode,
      };
      console.log("upload=" + JSON.stringify(upload));

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/lom/show";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.SubmitFlag = true;
      this.LomData = {};

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.ResultFlag = true;
            thisIns.LomData = records;
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
        });
    },
    getTableRecords(id) {
      console.log("getTableRecords called");
      console.log({ id });

      if (id > 0) {
        this.SubmitFlag = true;
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/recognition/show";

        var upload = {
          UserInterface: 1,
          Recognition: id,
        };

        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.SubmitFlag = false;
              console.log({ records });

              thisIns.record = records;
              console.log("record = " + JSON.stringify(thisIns.record));

              thisIns.toast("success", output);
            } else {
              thisIns.SubmitFlag = false;
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            thisIns.SubmitFlag = false;
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Np-Recognition Details not found.";
        this.toast("error", message);
      }
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: this.LomCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberDetails() {
      console.log("getMemberDetails called");
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.member = member;
      }
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
</style>