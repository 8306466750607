<template>
  <div id="award-type-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-title></v-card-title>
      <v-card-text>
        <!-- <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div> -->
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="getTotalNatConLomAwardsDetails"
        >
          <v-container class="py-0" v-if="record != ''">
            <h5 class="blue--text">Category Details</h5>
            <v-row wrap class="mb-3">
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6 class="text-dark">Category Title</h6>
                <h6>
                  {{ record.CategoryTitle }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6 class="text-dark">Category Description</h6>
                <h6>
                  {{ record.CategoryDescription }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6 class="text-dark">Category Type</h6>
                <h6>
                  {{ record.CategoryTypeTxt }}
                </h6>
              </v-col>
            </v-row>
            <h5 class="blue--text">Lom Details</h5>
            <v-row wrap class="mb-0" v-if="LomData != ''">
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6 class="text-dark">Lom Name</h6>
                <h6>
                  {{ LomData.LomName }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6 class="text-dark">President Name</h6>
                <h6>
                  {{ LomData.President.LomPresidentFullName }}
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6 class="text-dark">President Membership Id</h6>
                <h6>
                  {{ LomData.President.LomPresidentMembershipId }}
                </h6>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="8" md="6">
                <h6 class="text-dark">Description</h6>
                <v-textarea
                  v-model="Description"
                  placeholder="Description"
                  required
                  outlined
                  dense
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                lg="4"
                md="6"
                v-if="record.CategoryType == 2"
              >
                <h6><span class="text-danger">* </span> Select Member</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="MemberIdOptionsLoading"
                  :items="MemberIdOptions"
                  :rules="MemberIdRules"
                  v-model="MemberId"
                  required
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:selection="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="record.CategoryType == 2 && MemberId > 0 && member != ''"
              class="text-dark mb-3"
            >
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img width="100" height="auto" :src="member.photo" />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Name</label>
                <h5>{{ member.member_name }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Membership Id</label>
                <h5>{{ member.membership_id }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Date of Birth</label>
                <h5>{{ member.member_dob }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Mobile No</label>
                <h5>{{ member.mobile_no }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>Email Id</label>
                <h5>{{ member.email_id }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="8">
                <label>Address</label>
                <h5>{{ member.address_txt }}</h5>
              </v-col>
            </v-row>

            <h6 class="purple--text mt-4">
              Add / Delete Attachment (max - {{ CategoryMaxCount }})
              <v-btn icon color="blue" @click="addNewRow()">
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </h6>

            <template v-if="AllProject.length > 0">
              <v-row class="ml-0">
                <v-col
                  v-for="(data, index) in AllProject"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="3"
                  xl="3"
                  :key="index"
                >
                  <v-card>
                    <v-card-text>
                      <h6 class="text-right">
                        <v-btn
                          v-if="data.EventId != ''"
                          icon
                          color="red"
                          @click="deleteRow(index)"
                        >
                          <v-icon>mdi-delete-circle-outline</v-icon>
                        </v-btn>
                      </h6>
                      <h6 align="center">
                        <viewer rebuild :images="data.EventPhoto">
                          <img
                            style="padding: 5px"
                            width="70"
                            height="70"
                            :src="src"
                            :key="src"
                            v-for="src in data.EventPhoto"
                          />
                        </viewer>
                      </h6>
                      <h6>
                        Event Date -
                        <span class="text-dark">{{ data.EventDate }}</span>
                      </h6>
                      <h6>
                        Area-
                        <span class="text-dark">{{ data.PortfolioName }}</span>
                      </h6>
                      <h6>
                        Event Name -
                        <span class="text-dark">{{ data.EventName }}</span>
                      </h6>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-container>
          <v-row wrap class="mt-4">
            <v-col align="center" cols="12" md="12">
              <v-btn
                :disabled="!valid1"
                @click.prevent="getTotalNatConLomAwardsDetails(4)"
                :loading="SubmitFlag"
                elevation="30"
                medium
                color="#f3722c"
                class="font-size-h6 px-3 mr-3 white--text"
              >
                Save
              </v-btn>
              <v-btn
                :disabled="!valid1"
                @click.prevent="getTotalNatConLomAwardsDetails(3)"
                :loading="SubmitFlag"
                elevation="30"
                medium
                color="#43aa8b"
                class="font-size-h6 px-3 mr-3 white--text"
              >
                Final Submit
              </v-btn>
              <v-btn
                @click="closePrompt"
                elevation="30"
                medium
                color="red"
                class="font-size-h6 px-3 mr-3 white--text"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addAttachmentPrompt" persistent max-width="70%">
      <answer-attachment-create
        pageTitle="Add Project Details"
        :addAttachmentPrompt="addAttachmentPrompt"
        @hideAddAttachmentPrompt="hideAddAttachmentPrompt($event)"
        v-if="addAttachmentPrompt"
      ></answer-attachment-create>
    </v-dialog>
  </div>
</template>

  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import AnswerAttachmentCreate from "@/view/pages/erp/jci-award/np-recognition/AnswerAttachmentCreate.vue";

export default {
  mixins: [common],
  components: {
    AnswerAttachmentCreate,
  },
  props: {
    editRecordPrompt: {
      required: true,
      type: Boolean,
    },
    recordData: {
      required: true,
    },
    recognitionDetails: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      Status: "",
      NpRecognitionStartDate: "",
      NpRecognitionEndDate: "",

      StartDateFlag: false,
      EndDateFlag: false,

      CurrentDateTime: "",
      CurrentDate: "",
      CurrentTime: "",
      CurrentAmPm: "",

      record: {},
      LomData: {},
      member: {},

      AllProject: [],
      row: {},

      Description: "",

      addAttachmentPrompt: false,

      LomCode: 0,
      CategoryMaxCount: 0,

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      YearCode: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        this.YearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    MemberIdOptions: function () {
      console.log("watch MemberIdOptions called.");

      this.MemberIdOptionsLoading = false;
      var tr = this.record;
      this.MemberId = tr.MemberId;
    },
    MemberId: function () {
      console.log("watch MemberId");
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log({ member });
      }
      this.getMemberDetails();
    },
    record: function () {
      console.log("Watch record called");
      var tr = this.record;
      console.log("tr =" + JSON.stringify(tr));

      this.Description = tr.RecognitionDescription;
      this.CategoryMaxCount = tr.CategoryMaxCount;
      this.LomCode = tr.LomId;

      this.getLomDetails();
      this.getMemberIdOptions();
      this.AllProject = [];

      tr.EventDetails.forEach((row) => {
        this.AllProject.push({
          EventId: row["EventId"],
          EventName: row["EventName"],
          EventDate: row["EventDate"],
          PortfolioId: row["PortfolioId"],
          PortfolioName: row["PortfolioName"],
          EventDescription: row["EventDescription"],
          EventPhoto: row["EventImagesArray"],
        });
      });

      console.log("AllProject =" + JSON.stringify(this.AllProject));
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "np_recognition",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.LoadingFlag = false;
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt", this.ResultFlag);
    },
    pageData() {
      console.log("pageData called");
      console.log(
        "recognitionDetails = " + JSON.stringify(this.recognitionDetails)
      );
      console.log("recordData =" + JSON.stringify(this.recordData));
      var id = this.recordData.RecognitionId;

      console.log({ id });
      if (id > 0) {
        this.getTableRecords(id);
      }
    },
    getLomDetails() {
      console.log("getLomDetails called");

      var upload = {
        UserInterface: 1,
        Lom: this.LomCode,
      };
      console.log("upload=" + JSON.stringify(upload));

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/lom/show";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.SubmitFlag = true;
      this.LoadingFlag = true;
      this.LomData = {};

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;
          thisIns.LoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.LomData = records;
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
          thisIns.LoadingFlag = false;
        });
    },
    getTableRecords(id) {
      console.log("getTableRecords called");
      console.log({ id });

      if (id > 0) {
        this.SubmitFlag = true;
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/recognition/show";

        var upload = {
          UserInterface: 1,
          Recognition: id,
        };

        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.SubmitFlag = false;
              console.log({ records });

              thisIns.record = records;
              console.log("record = " + JSON.stringify(thisIns.record));

              thisIns.toast("success", output);
            } else {
              thisIns.SubmitFlag = false;
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);

            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Np-Recognition Details not found.";
        this.toast("error", message);
      }
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 7,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberDetails() {
      console.log("getMemberDetails called");
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.member = member;
      }
    },
    addNewRow(tr) {
      console.log("addNewRow called");
      console.log({ tr });
      this.addAttachmentPrompt = true;
    },
    hideAddAttachmentPrompt(tr) {
      console.log("hideAddAttachmentPrompt called");
      this.addAttachmentPrompt = false;
      console.log({ tr });

      if (tr.EventId != "") {
        var n1 = this.AllProject.length;
        var n2 = this.CategoryMaxCount;
        console.log({ n1 }, { n2 });

        if (n1 < n2) {
          this.AllProject.push({
            EventId: tr.EventId,
            EventName: tr.EventName,
            EventDate: tr.EventDate,
            PortfolioName: tr.PortfolioName,
            EventPhoto: tr.EventPhoto,
          });
        } else {
          var Message = "Attachment Maximum Count reached its limit.";
          this.sweetAlert("error", Message, false);
        }
      }
      console.log("AllProject =" + JSON.stringify(this.AllProject));
    },
    deleteRow(index) {
      console.log("deleteRow called");
      console.log({ index });
      if (index > -1) {
        this.AllProject.splice(index, 1);
      }
      console.log("AllProject =" + JSON.stringify(this.AllProject));
    },
    getCurrentDateTime(status) {
      console.log("getCurrentDateTime called");

      console.log({ status });
      this.Status = status;

      // Get new date
      var dateObj = new Date();

      var temp_date = String(dateObj.getDate()).padStart(2, "0");
      var month = String(dateObj.getMonth() + 1).padStart(2, "0");
      var year = dateObj.getFullYear();

      // Get Current Date
      var current_date = year + "-" + month + "-" + temp_date;

      // var temp_hour = String(dateObj.getHours()).padStart(2, "0") % 12;
      // var hour = temp_hour ? temp_hour : 12;
      var hour = String(dateObj.getHours()).padStart(2, "0");
      var minute = String(dateObj.getMinutes()).padStart(2, "0");
      var second = String(dateObj.getSeconds()).padStart(2, "0");

      // Get Current Time
      var current_time = hour + ":" + minute + ":" + second;

      // Get Current AM/PM
      // var current_am_pm = dateObj.getHours() >= 12 ? "pm" : "am";

      console.log({ current_date }, { current_time });

      this.CurrentDate = current_date;
      this.CurrentTime = current_time;
      // this.CurrentAmPm = current_am_pm;

      var current_date_time = current_date + " " + current_time;

      this.CurrentDateTime = current_date_time;

      var tr = this.recognitionDetails;
      if (tr.StartDate1 != "" && tr.EndDate1 != "" && current_date_time != "") {
        this.getDeadlineDetails(tr.StartDate1, tr.EndDate1);
      }
    },
    getDeadlineDetails(start_date_txt, end_date_txt) {
      console.log("getDeadlineDetails called");

      console.log({ start_date_txt }, { end_date_txt });

      // this.CurrentDateTime = "2023-08-01 00:00:00";
      var current_date_time = Date.parse(this.CurrentDateTime);
      console.log({ current_date_time });

      // Np-Recognition Start Date
      var temp_start_date_time = start_date_txt;
      // temp_start_date_time = "2023-08-01 00:00:01"
      var start_date_time = Date.parse(temp_start_date_time);
      console.log({ temp_start_date_time }, { start_date_time });

      if (start_date_time <= current_date_time) {
        console.log("Opened");
        this.StartDateFlag = true;
      } else {
        console.log("Closed");
      }

      // Np-Recognition End Date

      var temp_end_date_time = end_date_txt;
      var end_date_time = Date.parse(temp_end_date_time);
      console.log({ temp_end_date_time }, { end_date_time });

      if (end_date_time > current_date_time) {
        console.log("Opened");
        this.EndDateFlag = true;
      } else {
        console.log("Closed");
      }

      console.log("StartDateFlag = ", this.StartDateFlag);
      console.log("EndDateFlag = ", this.EndDateFlag);
      if (this.StartDateFlag && this.EndDateFlag) {
        this.confirmAlert();
      } else {
        this.deadlineAlert();
      }
    },
    deadlineAlert() {
      console.log("deadlineAlert called");
      var tr = this.recognitionDetails;
      var htmlTxt = "";
      htmlTxt += "<h2 style='color:red;'> Data Failed to Save ! </h2>";
      htmlTxt += "<br/><h3> Deadline Passed at ";
      htmlTxt += "<br/> <b> " + tr.EndDateTxt + " </b> </h3>";

      Swal.fire({
        title: "Sorry !",
        text: "",
        icon: "warning",
        html: htmlTxt,
      });
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      var status = this.Status;
      console.log({ status });
      var tr = this.recordData;
      var tr1 = this.LomData.President;

      var MemberId =
        tr.CategoryType == 2 ? this.MemberId : tr1.LomPresidentMemberId;
      var n1 = this.AllProject.length;
      console.log({ MemberId }, { n1 });

      if (MemberId > 0 && n1 > 0 && status != "") {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm(status);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message;
        if (MemberId < 0 || MemberId == null || MemberId == undefined) {
          message = "Member Deails not found.";
          this.sweetAlert("error", message, false);
        }
        if (n1 == 0) {
          message = "Atleast one Attachment is reqired.";
          this.sweetAlert("error", message, false);
        }
      }
    },
    submitForm(status) {
      console.log("submitForm is called");

      var tr = this.record;
      var tr1 = this.LomData.President;

      var MemberId =
        tr.CategoryType == 2 ? this.MemberId : tr1.LomPresidentMemberId;
      var n1 = this.AllProject.length;
      console.log({ MemberId }, { n1 });
      // MemberId =0;
      if (MemberId > 0 && n1 > 0) {
        var upload = {
          UserInterface: 1,
          Recognition: tr.RecognitionId,
          Description: this.Description,
          MemberId: MemberId,
          ActiveStatus: status,
          AllProject: this.AllProject,
        };
        console.log("upload=" + JSON.stringify(upload));

        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;

        var add_url = server_url + "api/recognition/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.SubmitFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);
            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      }
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
</style>